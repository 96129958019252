/* Large Screen */
.Home-Stats-Wrap {
  background-color: #c4dcf5;
  padding: 3.5em 2em;
  display: flex;
  justify-content: center;
}

.Home-Stats {
  background-color: #fff;
  padding: 1em 2em;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 2px 5px -2px #464646;
}

.Stat-Subtext {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75em 0;
  text-align: center;
  margin: 0 6px;
}

.Stat-Subtext .stat {
  color: #3987ba;
  font-size: 1.7em;
  font-weight: 700;
  padding-bottom: 0.25em;
}

.Stat-Subtext .subtext {
  color: #464646;
  font-weight: 500;
  font-size: 0.9em;
}

.Stat-Box {
  display: flex;
  flex-direction: row;
}

.Stat-Box-Mobile {
  display: flex;
  flex-direction: row;
  width: 9.35em;
  justify-content: center;
}

.Vertical-Line {
  border-left: solid 0.5px #3987ba;
  height: 100%;
  margin: 0 2em;
}

/* Mobile Screen */
.Home-Stats-Mobile-Wrap {
  border-radius: 10px;
  background-color: #fff;
  padding: 1.5em;
  text-align: center;
  margin: 1.5em;
  border: solid 0.5px #3987ba;
  box-shadow: 0px 3px 8px -2px #3987ba;
  border-radius: 10px;
}

.Home-Stats-Mobile-Wrap .Text {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.Home-Stats-Mobile {
  padding-top: 1em;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media screen and (min-width: 769px) {
  .Stat-Subtext {
    margin: 0;
  }

  .Stat-Subtext .stat {
    font-size: 2.1em;
    font-weight: 600;
  }

  .Stat-Subtext .subtext {
    font-weight: normal;
    font-size: 1.2em;
  }
}
