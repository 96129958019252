.Download-Title {
  font-size: 18px;
  font-weight: 500;
  color: #464646;
  padding: 1em 0;
}

.Download-Img-Contents {
  padding: 0 10px 16px 10px;
  display: flex;
  align-items: center;
}

.Download-Img-Contents img {
  width: 25vw;
  height: 100%;
  max-width: 280px;
  object-fit: contain;
}

.Download-Contents {
  padding-left: 12px;
}

.Download-Text {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  color: #333;
}

.Download-Stats {
  display: flex;
  justify-content: space-evenly;
}

.Download-Stats .stat {
  font-size: 12px;
}

.Download-Stats .subtext {
  font-size: 10px;
  font-weight: normal;
}

.Download-Buttons {
  display: flex;
  justify-content: space-evenly;
}
.Download-Button {
  border-radius: 8px;
  border: 1px solid #3987ba;
  background-color: #3a87bb19;
  padding: 8px 6px 8px 8px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
}

.Download-Button img {
  width: 21px;
  height: 24px;
}

.Download-Button span {
  padding-left: 6px;
  text-align: left;
  font-size: 7px;
  color: #333;
}

@media screen and (min-width: 769px) {
  .Download-Title {
    font-size: 2.5rem;
  }

  .Download-Img-Contents {
    padding: 0 4vw 0 16vw;
  }

  .Download-Contents {
    padding: 4vw 4vw 4vw 6vw;
  }

  .Download-Img-Contents img {
    width: 100%;
  }

  .Download-Text {
    font-size: 1.25rem;
  }

  .Download-Stats {
    padding: 2em 0;
  }

  .Download-Stats .stat {
    font-size: 2.5rem;
  }

  .Download-Stats .subtext {
    font-size: 1.125rem;
    font-weight: bold;
  }

  .Download-Button {
    padding: 16px 20px;
  }

  .Download-Button img {
    width: 36px;
    height: 48px;
  }

  .Download-Button span {
    padding-left: 16px;
    font-size: 1rem;
  }
}
