.Footer {
  margin: 32px 24px;
  border-radius: 10px;
  background-color: #3a87bb19;
  display: flex;
  flex-direction: column;
}

.Simple-Footer {
  background-color: #3a87bb19;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 6px 0;
}

.Simple-Footer span {
  font-size: 14px;
  font-weight: 500;
  color: #747474;
  margin: 12px 0 6px 0;
}

.Simple-Footer .Esign-Government-Images {
  display: flex;
  margin: 0;
  align-items: center;
}

.Simple-Footer .Esign-Image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  margin: 8px;
}

.Simple-Footer .Government-Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 30px;
  margin: 8px;
}

.divider {
  padding: 0 24px;
}

.divider img {
  width: 100%;
}

.Logo-Address {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer img {
  width: 40vw;
  height: 100%;
  max-width: 160px;
  padding: 24px 0;
}

.Footer span {
  font-size: 14px;
  color: #464646;
  text-align: center;
}

.Logo-Wrap span strong {
  font-weight: 500;
  color: #6027d0;
}

.Products-Who-we-are {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #6027d0;
  justify-content: center;
}

.Products-Who-we-are div {
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Products-Who-we-are a {
  padding-top: 4px;
  font-size: 14px;
  font-weight: normal;
  color: #464646;
  text-decoration: none;
}

.Products-Who-we-are a:hover {
  font-weight: bold;
}

.Social-Media {
  text-align: center;
  margin: 24px 0;
  color: #464646;
}

.Social-Links {
  display: flex;
  justify-content: center;
}

.Icon {
  background-color: #fff;
  border-radius: 5px;
  margin: 8px;
  padding: 4px;
}

.Copy-Right {
  padding: 0 24px;
  text-align: center;
  font-size: 14px;
  color: #464646;
  margin-bottom: 24px;
}

.Copy-Right span a {
  color: #6027d0;
  text-decoration: underline;
}

.Copy-Right span em {
  color: #6027d0;
  font-size: 18px;
}

.Policy-Links {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #3987ba;
  padding: 16px 24px;
  font-size: 14px;
}

.Policy-Links a {
  text-decoration: none;
  color: #fff;
  padding: 8px 0;
}

@media screen and (min-width: 769px) {
  .Footer {
    margin: 56px 0 0 0;
  }

  .Simple-Footer {
    padding: 24px 0 12px 0;
  }

  .Simple-Footer span {
    font-size: 20px;
  }

  .divider img {
    max-width: 800px;
  }

  .Simple-Footer .Esign-Government-Images {
    margin: 0;
  }

  .Logo-Wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 12vw 32px 12vw;
  }

  .Policy-Links {
    border-radius: 0;
    flex-direction: row;
    justify-content: center;
  }

  .Policy-Links a {
    padding: 0 30px;
  }
}
